import React from 'react';
import HeroSlider from '../HeroSlider/HeroSlider.js';
// import AI4Good from '../../components/AI4Good/AI4Good';
import News from '../News/News.jsx';
// import CookieManager from '../CookieConsent/CookieConsent.jsx'
// import Marquee from '../Marquee/marquee.jsx';
// import SvgMapComponent from '../GBA/svgmap.jsx';

const HomePage = () => {
  return (
    <>
      {/* <section id="home"> */}
      <HeroSlider />
      {/* </section> */}
      {/* <section id="solutions"> */}
      <News />
      {/* <Marquee /> */}
      {/* <SvgMapComponent /> */}
      {/* </section> */}
      {/* <section id="news">
        <News />
      </section> */}
      {/* <section id="aboutus">
        <AboutUs />
      </section> */}
      {/* <CookieManager /> */}

    </>
  );
};

export default HomePage;