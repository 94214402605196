// Header.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import languages from '../i18n/languages.json';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const Header = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const newLanguage = checkAndUpdateLanguage(i18n.language);
    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
    }
  }, [i18n]);

  const changeLanguage = (lng) => {
    const newLanguage = checkAndUpdateLanguage(lng);
    i18n.changeLanguage(newLanguage).then(() => {
      console.log('Language changed to:', newLanguage);
      window.dispatchEvent(new Event('languageChanged'));
    });
  };

  const handleNavClick = (event, path, sectionId) => {
    event.preventDefault();
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      } else {
        navigate(path);
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      }
    } else {
      navigate(path);
    }
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" className="full-width-header">
      <Navbar.Brand as={Link} to="/">TAIWAN.AI</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link onClick={(event) => handleNavClick(event, '/')}>{t('Home')}</Nav.Link>
          <Nav.Link onClick={(event) => handleNavClick(event, '/AI4Good')}>{t('A.I. Transformation')}</Nav.Link>
          <Nav.Link onClick={(event) => handleNavClick(event, '/aboutus')}>{t('About Us')}</Nav.Link>
          <NavDropdown 
            title={
              <span>
                <i className="fas fa-globe">&nbsp; </i> {t('Language')}
              </span>
            } 
            id="language-nav-dropdown"
          >
            {Object.entries(languages).map(([code, name]) => (
              <NavDropdown.Item key={code} onClick={() => changeLanguage(code)}>
                {name}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;