// HeroSlider.js
import React, { useState, useEffect, useCallback } from 'react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CarouselComponent.css';
import { checkAndUpdateLanguage } from '../i18n/i18n';

// Import all slider data files
import sliderDataEn from './en.json';
import sliderDataSc from './sc.json';
import sliderDataTc from './tc.json';

const HeroSlider = () => {
  const { i18n } = useTranslation();
  const [sliderData, setSliderData] = useState([]);

  const loadSliderData = useCallback(() => {
    const language = checkAndUpdateLanguage(i18n.language);
    let data;

    switch (language) {
      case 'en':
        data = sliderDataEn;
        break;
      case 'sc':
        data = sliderDataSc;
        break;
      case 'tc':
        data = sliderDataTc;
        break;
      default:
        data = sliderDataEn; // fallback to English
    }

    // Ensure data is an array
    setSliderData(Array.isArray(data) ? data : Object.values(data));
  }, [i18n.language]);

  useEffect(() => {
    loadSliderData();

    const handleLanguageChange = () => {
      loadSliderData();
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [i18n.language, loadSliderData]);

  return (
    <Carousel data-bs-interval="10000">
      {Array.isArray(sliderData) && sliderData.map((slide, index) => (
        <Carousel.Item key={index} style={{ backgroundImage: `url('${slide.image}')` }}>
          <Carousel.Caption>
            <h3>{slide.title}</h3>
            <p>{slide.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default HeroSlider;