import React, { useEffect } from "react";
import "./footer.css";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const Footer = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const language = checkAndUpdateLanguage(i18n.language);
        console.log('Footer component rendered. Current language:', language);

        const handleLanguageChange = () => {
            console.log('Language change event detected in Footer');
            checkAndUpdateLanguage(i18n.language);
        };

        window.addEventListener('languageChanged', handleLanguageChange);

        return () => {
            window.removeEventListener('languageChanged', handleLanguageChange);
        };
    }, [i18n.language]);

    return (
        <>
            <Helmet>
                <title>{t('TAIWAN.AI - To leverage A.I. for the improvement of people\'s lives and the benefit of society.')}</title>
                <meta name="description" content={t('To leverage A.I. for the improvement of people\'s lives and the benefit of society.')} />
                <meta name="keywords" content={t('Artificial Intelligence, AI, Society, improvement, people\'s lives, Ethical AI, Hong Kong AI, benefit of society')} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="canonical" href="https://taiwan.ai/" />
                <meta httpEquiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline';" />
                <meta httpEquiv="X-Frame-Options" content="DENY" />
            </Helmet>

            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-links">
                            <Link to="/privacy-policy">{t('Privacy Policy')}</Link>
                            <Link to="/TermsOfUse">{t('Terms of Use')}</Link>
                        </div>
                        <div className="footer-copyright">
                            {t('Copyright {{year}} ©TAIWAN.AI All Rights Reserved.', { year: new Date().getFullYear() })}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;