// news.js
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Row, Col, Container, Pagination, Alert, Modal, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './News.css';
import Helmet from 'react-helmet';
import CardComponent from './CardComponent';
import newsData from './newsData.json'; // Import the news data

const NewsEdit = () => {
    const newsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const [saveSuccess, setSaveSuccess] = useState(null);
    const [newsArticles, setNewsArticles] = useState(newsData);
    const [showModal, setShowModal] = useState(false);
    const [editingArticle, setEditingArticle] = useState(null);
    // Add state for form fields
    const [articleImage, setArticleImage] = useState('');
    const [articleDate, setArticleDate] = useState('');
    const [articleTitle, setArticleTitle] = useState('');
    const [articleText, setArticleText] = useState('');
    const [articleUrl, setArticleUrl] = useState('');

    const pageCount = Math.ceil(newsArticles.length / newsPerPage);
    const currentNews = useMemo(() => newsArticles.slice(
        (currentPage - 1) * newsPerPage,
        currentPage * newsPerPage
    ), [currentPage, newsPerPage, newsArticles]);

    const handlePageClick = useCallback((pageNumber) => {
        setCurrentPage(pageNumber);
    }, []);

    const paginationItems = useMemo(() => {
        let items = [];
        for (let number = 1; number <= pageCount; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageClick(number)}
                    aria-label={`Go to page ${number}`}
                >
                    {number}
                </Pagination.Item>,
            );
        }
        return items;
    }, [pageCount, currentPage, handlePageClick]);

    // Add CRUD functions

    const handleCreateOrUpdateArticle = (article) => {
        if (editingArticle) {
            // Update existing article
            setNewsArticles(newsArticles.map(a => (a.title === editingArticle.title ? article : a)));
        } else {
            // Create new article
            setNewsArticles([...newsArticles, article]);
        }
        handleCloseModal();
    };

    const handleDeleteArticle = (title) => {
        setNewsArticles(newsArticles.filter(article => article.title !== title));
    };

    const handleEditArticle = (article) => {
        setEditingArticle(article);
        // Pre-fill form with article data
        setArticleImage(article.image);
        setArticleDate(article.date);
        setArticleTitle(article.title);
        setArticleText(article.text);
        setArticleUrl(article.url);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingArticle(null);
        setArticleImage('');
        setArticleDate('');
        setArticleTitle('');
        setArticleText('');
        setArticleUrl('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newArticle = {
            image: articleImage,
            date: articleDate,
            title: articleTitle,
            text: articleText,
            url: articleUrl,
        };
        handleCreateOrUpdateArticle(newArticle);
    };

    // Simulate a save operation
    const saveArticles = () => {
        // Here you would typically make an API request to save the news articles to a database
        console.log('Articles to be saved:', newsArticles);
        return new Promise((resolve) => {
            setTimeout(() => {
                // Simulate saving success
                resolve(true);
            }, 1000);
        });
    };

    const handleSave = async () => {
        const success = await saveArticles();
        setSaveSuccess(success);
    };

    useEffect(() => {
        if (saveSuccess) {
            console.log('Save was successful!');
        } else if (saveSuccess === false) {
            console.log('Save failed.');
        }
    }, [saveSuccess]);

    return (
        <>
            <Helmet>
                {/* ... Helmet content ... */}
            </Helmet>

            {/* ... Rest of the component ... */}

            {/* Add button to add new article */}
            <div className="text-center mb-3">
                <Button variant="primary" onClick={() => setShowModal(true)}>Add New Article</Button>
            </div>

            {/* Modal for creating and updating articles */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingArticle ? 'Edit Article' : 'Add Article'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formArticleImage">
                            <Form.Label>Image URL</Form.Label>
                            <Form.Control
                                type="text"
                                value={articleImage}
                                onChange={(e) => setArticleImage(e.target.value)}
                                placeholder="Enter image URL"
                            />
                        </Form.Group>
                        <Form.Group controlId="formArticleDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                                type="text"
                                value={articleDate}
                                onChange={(e) => setArticleDate(e.target.value)}
                                placeholder="Enter date"
                            />
                        </Form.Group>
                        <Form.Group controlId="formArticleTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={articleTitle}
                                onChange={(e) => setArticleTitle(e.target.value)}
                                placeholder="Enter title"
                            />
                        </Form.Group>
                        <Form.Group controlId="formArticleText">
                            <Form.Label>Text</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={articleText}
                                onChange={(e) => setArticleText(e.target.value)}
                                placeholder="Enter article text"
                            />
                        </Form.Group>
                        <Form.Group controlId="formArticleUrl">
                            <Form.Label>Article URL</Form.Label>
                            <Form.Control
                                type="text"
                                value={articleUrl}
                                onChange={(e) => setArticleUrl(e.target.value)}
                                placeholder="Enter article URL"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {editingArticle ? 'Update' : 'Create'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* ... Rest of the component ... */}

            <Row>

                <Row>
                    {currentNews.map((article, index) => (
                        <Col lg={3} md={6} sm={12} key={index} className="mb-4 d-flex flex-column">
                            <CardComponent
                            // ... (props)
                            image={article.image}
                            date={article.date}
                            title={article.title}
                            text={article.text}
                            url={article.url}
                            alt={`${article.title} - ${article.text.substring(0, 100)}...`}
                            />
                            <div className="mt-auto">
                                <Button
                                    className="edit-button button-gap w-100"
                                    onClick={() => handleEditArticle(article)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    className="delete-button w-100"
                                    onClick={() => handleDeleteArticle(article.title)}
                                >
                                    Delete
                                </Button>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Row >

            {/* ... Rest of the component ... */}

            < Container >
                {/* Alert for save success or failure */}
                {
                    saveSuccess !== null && (
                        <Alert variant={saveSuccess ? 'success' : 'danger'}>
                            {saveSuccess ? 'Articles saved successfully!' : 'Failed to save articles.'}
                        </Alert>
                    )
                }

                {/* Pagination */}
                <Pagination className="justify-content-center">
                    {paginationItems}
                </Pagination>

                {/* Save button */}
                <div className="text-center mb-3">
                    <Button variant="success" onClick={handleSave}>Save Articles</Button>
                </div>
            </Container >

            {/* ... Rest of the component ... */}

        </>
    )
}
export default NewsEdit;