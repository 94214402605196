// src/components/web/i18n/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import enTranslations from './en.json';
import scTranslations from './sc.json';
import tcTranslations from './tc.json';

export const checkAndUpdateLanguage = (currentLanguage) => {
  let newLanguage = currentLanguage;
  if (currentLanguage === 'zh-CN') {
    newLanguage = 'sc';
  } else if (currentLanguage === 'zh-TW') {
    newLanguage = 'tc';
  }
  if (newLanguage !== i18n.language) {
    i18n.changeLanguage(newLanguage);
  }
  return newLanguage;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: enTranslations },
      sc: { translation: scTranslations },
      tc: { translation: tcTranslations },
    },
  });

export default i18n;