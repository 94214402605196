import React from 'react';
import HeroSlider from '../HeroSlider/HeroSlider';
import AI4Good from './AI4Good';
// import News from '../../components/News/News.js';

const HomePage = () => {
  return (
    <>
      <section id="home">
        <HeroSlider />
      </section>
      {/* <section id="solutions">
        <Card />
      </section> */}
      {/* <section id="news">
        <News />
      </section> */}
      {/* <section id="aboutus"> */}
        <AI4Good />
      {/* </section> */}
    </>
  );
};

export default HomePage;